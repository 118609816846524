<template>
  <div>
    <v-select
      v-model="filter"
      dense
      hide-details
      :items="allItems"
      outlined
      label="Filter by Order Type"
      class="input-max-width"
      color="primary"
    />
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import { orderType } from '@/shared/models/noww'

export default {
  data: function () {
    return {
      all: [
        { text: 'All Order Types', value: null }
      ]
    }
  },

  computed: {
    ...mapGetters('auth', ['hasClaimKV']),
    filter: {
      get () {
        return this.$store.getters.orderTypeFilter()
      },
      set (value) {
        this.$store.dispatch('setOrderTypeFilter', value)
      }
    },
    availableOrderTypes () {
      return orderType.filter(x => !x.claim || this.hasClaimKV(x.claim))
    },
    allItems () {
      return [...this.all, ...this.availableOrderTypes]
    }
  }
}
</script>
