var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c("v-select", {
        staticClass: "input-max-width",
        attrs: {
          dense: "",
          "hide-details": "",
          items: _vm.allItems,
          outlined: "",
          label: "Filter by Order Type",
          color: "primary"
        },
        model: {
          value: _vm.filter,
          callback: function($$v) {
            _vm.filter = $$v
          },
          expression: "filter"
        }
      })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }